<template>
  <div class="main-display">
    <transition
      name="fade"
      mode="out-in"
      :duration="{ enter: transitionDuration.enter, leave: transitionDuration.leave }"
    >
      <component :is="currentComponent" :content-data="currentContentData" />
    </transition>
  </div>
</template>

<script>
import apiTimer from "../api/apiTimer";
import MainContent from "../display/MainContent.vue";
import TeamContent from "../display/TeamContent.vue";
import ContentSlide from "../display/ContentSlide.vue"; 
import apiContent from "../api/apiContent"; 

export default {
  components: {
    TeamContent,
    MainContent,
    ContentSlide
  },
  data() {
    return {
      componentsList: [],
      contentData: [],
      currentComponentIndex: 0,
      isDataLoaded: false,
      timer: 10000 
    };
  },

  async created() {
    await this.fetchContentData();
    this.isDataLoaded = true;
  },

  async mounted() {
    await this.fetchContentData();
    try {
      const timerInSeconds = await apiTimer.getTimer(); 
      this.timer = timerInSeconds * 1000; 
      this.startSlider(); 
    } catch (error) {
      console.error("Fout bij het laden van de timer: ", error);
      this.startSlider(); 
    }
  },

  methods: {
    async fetchContentData() {
  const data = await apiContent.getContent();
  this.contentData = data.sort((a, b) => a.order - b.order);  // Zorg dat de data gesorteerd is
  this.componentsList = ["TeamContent", "MainContent"];  // Voeg TeamContent en MainContent toe

  // Voeg ContentSlides toe op basis van hun order, met uitzondering van order 0, want die wordt al als eerste getoond
  this.contentData.forEach(item => {
    if (item.order === 0) {
      this.componentsList.unshift("ContentSlide");  // Voeg ContentSlide voor order 0 toe aan het begin
    } else {
      // Voor alle andere orders, voeg ContentSlide toe na TeamContent en MainContent
      this.componentsList.push("ContentSlide");
    }
  });
},

    toggleComponent() {
      if (this.isDataLoaded) { 
        this.currentComponentIndex =
          (this.currentComponentIndex + 1) % this.componentsList.length;
      }
    },
    startSlider() {
      setInterval(() => {
        this.toggleComponent();
      }, this.timer);
    },
  },

  computed: {
    currentComponent() {
    return this.componentsList[this.currentComponentIndex];
  },
  currentContentData() {
    if (this.currentComponent === "ContentSlide") {
      // Vind het juiste content item door overeenkomst te vinden met de huidige componentindex
      let count = -1;  // Beginnen met -1 omdat unshift de eerste 'ContentSlide' op index 0 plaatst
      for (let i = 0; i < this.componentsList.length; i++) {
        if (this.componentsList[i] === "ContentSlide") {
          count++;
        }
        if (i === this.currentComponentIndex) {
          return this.contentData[count];
        }
      }
    }
    return null;
  },
    transitionDuration() {
      return {
        enter: 1000,
    leave: 500  
      };
    }
  }
};
</script>
