<template>
  <v-container>
    <div class="teams">
      <img src="@/assets/logo.jpg" height="150" width="250" />
      <h2
        style="color:black;text-align:center;margin-bottom:5px;color: #00a48f;"
      >
        Welkom bij Tandartspraktijk Waldent
      </h2>
      <h3 style="color:black;text-align:center;margin-bottom:5px">
        Team van vandaag / Team of today
      </h3>
      <v-row>
        <v-col v-for="(card, index) in cards" :key="index" cols="12" md="3">
          <v-card max-width="400">
            <v-img
              :src="
                card.images[0]
                  ? `https://digitalsignbackend.waldent.nl/static/uploads/${card.images[0].filename}`
                  : require('@/assets/logo.jpg')
              "
            ></v-img>
            <v-card-title>{{ card.name }}</v-card-title>
            <v-card-subtitle>{{ card.title }}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiTeam from "../api/apiTeam";

export default {
  data() {
    return {
      search: "",
      cards: [],
      errorSnackbar: false,
      errorMessage: "",
    };
  },
  async mounted() {
    this.loadModules();
  },
  methods: {
    loadModules() {
      apiTeam
        .getAllTeams()
        .then((data) => {
          this.cards = data;
        })
        .catch((error) => {
          console.error("Failed to load modules:", error);
        });
    },
  },
};
</script>

<style scoped>
.teams {
  min-height: 90vh;
  position: relative;
  z-index: 2;
}
.main-display {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  max-width: 100%;
  height: auto;
}
</style>

<style>
.background {
  background-color: #00a48f;
  color: #00a48f;
}
</style>
