
<template>

  <Display />


</template>

<script>
import Display from "@/components/display/Display";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Display,
  
  },
  metaInfo: {
    title: "Integratiepartners digital sign login",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>