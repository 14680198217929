import { render, staticRenderFns } from "./ContentSlide.vue?vue&type=template&id=61b5fa86&scoped=true&"
import script from "./ContentSlide.vue?vue&type=script&lang=js&"
export * from "./ContentSlide.vue?vue&type=script&lang=js&"
import style0 from "./ContentSlide.vue?vue&type=style&index=0&id=61b5fa86&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61b5fa86",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
