<template>
  <div class="wallpaper-login">
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12 sm12 md8>
          <!--weather card-->
          <div class="card card-weather">
            <div class="card-body">
              <div class="weather-date-location">
                <h3 class="current-date"> {{ formattedDate }}</h3>
                <p class="text-gray">
                                <p class="weather-location">Bezuidenhoutseweg 193, 1, 2594 AJ Den Haag</p>
                  <span class="weather-date">{{ currentDateTime }}</span><br></p>
              </div>
               <div class="weather-data d-flex">
    <div class="mr-auto">
      <h4 class="display-3">
        {{ getCurrentDayTemperature }} <span class="symbol">&deg;</span>C
      </h4>
      <p>
        {{ getCurrentDayConditions }}
      </p>
    </div>
    </div>
            </div>
            <div class="card-body p-0">
              <div class="d-flex weakly-weather">
                
              <div class="card-body p-0">
 <div class="d-flex weakly-weather">
  <div v-for="(dayWeather, index) in dailyWeather" :key="index" class="weakly-weather-item">
    <p class="mb-0">
         <i class="mdi mdi-weather-cloudy"></i>
      {{ dayWeather.day }}
    </p>
    <i :class="'mdi mdi-weather-' + dayWeather.weatherIcon"></i>
    <p class="mb-0">
          <i class="mdi-weather"></i>
      {{ dayWeather.temperature }}&deg;C 
    </p>
  </div>
</div>

</div>

          </div>
          </div>
          </div>
        </v-flex>
        <v-flex xs12 sm12 md4>
          <!-- artwork here -->
          <div class="sidebar">
            <div class="logincontainer">
              <h1
                style="text-align:center;color:white
   "
              >
                {{ dailyTip.content }}
              </h1>

              <div style="margin-top:40px">
              <h1
                style="text-align:center;color:white
   "
              >
                {{ dailyTipEng.content }}
              </h1>
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import apiTeam from "../api/apiTeam";
export default {
  data() {
    return {
            dailyWeather: [], 
        weatherData: {},
         formattedDate: '',
            currentDateTime: "",
      dailyTip: "",
           dailyTipEng: "",
    };
  },
  created() {
    this.fetchWeatherData();
    this.updateFormattedDate();
    this.updateDateTime();
    setInterval(this.updateDateTime, 1000); 
  },

  async mounted() {
    this.loadDailyTips();
    this.loadDailyTipsEng();
  },
  computed: {
    getCurrentDayWeather() {
      const currentDate = new Date().toLocaleDateString("en-US", {
        weekday: "short",
      });

      return this.dailyWeather.find(day => day.day === currentDate);
    },
    getCurrentDayTemperature() {
      return this.getCurrentDayWeather ? this.getCurrentDayWeather.temperature : "";
    },
    getCurrentDayConditions() {
      return this.getCurrentDayWeather ? this.getCurrentDayWeather.conditions : "";
    },
  },
  methods: {
    loadDailyTips() {
      apiTeam
        .getDailyTips()
        .then((data) => {
          this.dailyTip = data;
        })
        .catch((error) => {
          console.error("Failed to load modules:", error);
        });
    },

       loadDailyTipsEng() {
      apiTeam
        .getDailyTipsEng()
        .then((data) => {
          this.dailyTipEng= data;
        })
        .catch((error) => {
          console.error("Failed to load modules:", error);
        });
    },
 
 

    kelvinToCelsius(kelvin) {
    return Math.round(kelvin - 273.15); // Conversie van Kelvin naar Celsius
  },
     updateDateTime() {
      const now = new Date();
      this.currentDateTime = now.toLocaleString();
    },
      updateFormattedDate() {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

      const currentDate = new Date();
      const dayName = days[currentDate.getDay()];
      const date = currentDate.getDate();
      const monthName = months[currentDate.getMonth()];
      const year = currentDate.getFullYear();

      this.formattedDate = `${dayName}, ${date} ${monthName} ${year}`;
    },
    //Weer
    async fetchWeatherData() {
  const apiKey = "0d8f2ab9b9b994720685415ca5c9412e";
  const city = "Den Haag";

  try {
    const response = await axios.get(
      `https://api.openweathermap.org/data/2.5/forecast?q=${city}&cnt=7&appid=${apiKey}`
    );
    const weatherData = response.data;

    const dailyWeather = weatherData.list.map(day => ({
      day: new Date(day.dt * 1000).toLocaleDateString("en-US", { weekday: "short" }),
      temperature: this.kelvinToCelsius(day.main.temp), // Convert Kelvin to Celsius
      weatherIcon: day.weather[0].icon, // Weather icon code
    }));

    this.dailyWeather = dailyWeather;
  } catch (error) {
    console.error("Fout bij het ophalen van weergegevens:", error);
  }
},


  },
};
</script>
<style scoped>
.main-display {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logincontainer {
  margin-top: -150px;
  padding: 30px;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 10;
}
.logincontainer button {
  background: #02192d;
  background-color: rgb(2, 25, 45);
  border: 0;
  border-radius: 2px;
  color: #eee !important;
  display: block;
  float: left;
  font-size: 11px;
  font-weight: 400;
  opacity: 0.8;
  outline: 0 !important;
  padding: 12px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: width 0.3s ease;
  width: auto;
}
.sidebar {
  background: #00a48f;
  border-radius: 0;
  justify-content: center;
  min-height: 110vh;
  padding: 0;
  position: relative;
  z-index: 2;
}

.wallpaper-login {
  animation-name: wallpaper-animation;
  animation-duration: 60s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  /* background-image: url("../../assets/1.jpg"); */
  background-size: cover;
  position: absolute;
  width: 100%;
}
</style>

<style scoped>
.stretch-card > .card {
  width: 100%;
  min-width: 100%;
}

.flex {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

@media (max-width: 991.98px) {
  .padding {
    padding: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .padding {
    padding: 1rem;
  }
}

.padding {
  padding: 5rem;
}

.grid-margin,
.purchace-popup > div {
  margin-bottom: 25px;
}

.card {
  margin-top: 2rem;
  border: 0;
  border-radius: 2px;
  height: 100vh !important;
}

.card-weather {
  background: #e1ecff;
  background-image: linear-gradient(
    to left bottom,
    #d6eef6,
    #dff0fa,
    #e7f3fc,
    #eff6fe,
    #f6f9ff
  );
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-weather .card-body:first-child {
  background: url(https://res.cloudinary.com/dxfq3iotg/image/upload/v1557323760/weather.svg)
    no-repeat center;
  background-size: cover;
}

.card .card-body {
  padding: 1.88rem 1.81rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-weather .weather-date-location {
  padding: 0 0 38px;
}

.h3,
h3 {
  font-size: 1.56rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.text-gray,
.card-subtitle,
.new-accounts ul.chats li.chat-persons a p.joined-date {
  color: #969696;
}

p {
  font-size: 13px;
}

.text-gray,
.card-subtitle,
.new-accounts ul.chats li.chat-persons a p.joined-date {
  color: #969696;
}

.card-weather .weather-data {
  padding: 0 0 4.75rem;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.display-3 {
  font-size: 2.5rem;
}

.card-weather .card-body {
  background: #ffffff;
}

.card-weather .weakly-weather {
  background: #ffffff;
  overflow-x: auto;
}

.card-weather .weakly-weather .weakly-weather-item {
  flex: 0 0 14.28%;
  border-right: 1px solid #f2f2f2;
  padding: 1rem;
  text-align: center;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.card-weather .weakly-weather .weakly-weather-item i {
  font-size: 1.2rem;
}
</style>
