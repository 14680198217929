import {API} from "./api.base.js"

export default {
  async getAllContent() {
    try {
      const response = await API.get("/api/rest/v2/admin/content/allContent");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getContent() {
    try {
      const response = await API.get("/api/rest/v2/content");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateContent(id,content) {
    try {
      const response = await API.put(`/api/rest/v2/admin/content/${id}`,content);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async createContent(data) {
    try {
      const response = await API.post(`/api/rest/v2/admin/content`,data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  

  async deleteContent(id) {
    try {
      const response = await API.delete(`/api/rest/v2/admin/content/${id}`,);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
