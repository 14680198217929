import axios from 'axios';

// const API = axios.create({
//   baseURL: window.location.hostname.includes('Integratiepartners')
//     ? 'http://localhost:3000'
//     : 'http://localhost:3000',
//   timeout: 30000,
// });

const API = axios.create({
  baseURL: window.location.hostname.includes('Integratiepartners')
    ? 'https://digitalsignbackend.waldent.nl'
    : 'https://digitalsignbackend.waldent.nl',
  timeout: 30000,
});
export { API };
