import { API } from "./api.base.js";

export default {
  async createTeam(data) {
    try {
      const response = await API.post("/api/rest/v2/team", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getTeams() {
    try {
      const response = await API.get("/api/rest/v2/team");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getAllAdminTeams() {
    try {
      const response = await API.get("/api/rest/v2/team/all");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getAllTeams() {
    try {
      const response = await API.get("/api/rest/v2/waldent/teams");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteTeam(id) {
    try {
      const response = await API.delete(`/api/rest/v2/team/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateTeam(id, form) {
    try {
      const response = await API.put(`/api/rest/v2/team/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSingleTeam(id) {
    try {
      const response = await API.get(`/api/rest/v2/team/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getDailyTips() {
    try {
      const response = await API.get("/api/rest/v2/dailytips");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getDailyTipsEng() {
    try {
      const response = await API.get("/api/rest/v2/dailytips/translated");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
