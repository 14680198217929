<template>
  <v-container class="background" fluid>
    <div>
      <img src="@/assets/logo.jpg" height="150" width="250" />
    </div>
    <div class="text text-center">
      <br />
      <br />
      <div v-if="contentData">
  <h1>{{ contentData.name }}</h1>
  <div v-html="contentData.content"></div>
</div>
<div v-else>
  Loading content... issie jere
</div>

    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    contentData: Object,
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}

.background {
  background: white;
  width: auto;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.text {
  color: #00a48f;
  margin: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
